import React                              from 'react';
import { graphql }                        from 'gatsby';
import Breakpoint                         from 'react-socks';
import Img                                from 'gatsby-image';
import styled                             from '@emotion/styled';
import { GiDiamondRing, GiWatch, GiShop } from 'react-icons/gi';
import { BsTools }                        from 'react-icons/bs';
import { Flex, Box, Button, Center }      from '@chakra-ui/react';


import Heading                   from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import LiveAnnouncements
                                 from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Mounted                   from '@interness/web-core/src/components/structure/Mounted/Mounted';
import RandomSquareGalleryImages from '@interness/brands-addon/src/components/media/RandomSquareGalleryImages';
import RandomBrandHeaderImages
                                 from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import SEO                       from '@interness/web-core/src/components/modules/SEO/SEO';
import FramedImage               from '@interness/theme-sonora/src/components/FramedImage';
import BrandsDisplay             from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import CallToAction              from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Link                      from '@interness/web-core/src/components/elements/Link/Link';

const SDisplay = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const SDisplayItem = styled.div`
    width: ${props => props.width};
    margin-bottom: 50px;
    position: relative;
    @media (max-width: 640px) {
        width: 100%;
    }

    > div {
        margin-bottom: 20px;
        position: relative;
        border: 5px solid rgba(0, 0, 0, 0.8);
    }

    h4 {
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-transform: uppercase;
        padding: 10px 0;
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }

    h5 {
        font-size: 1.2rem;
        font-weight: 300;
        text-align: left;
        margin-top: 10px;
    }

    p {
        text-align: justify;
        font-size: 0.9rem;
    }

    a {
        position: absolute;
        bottom: -65px;
    }
`;


const IndexPage = ({ data }) => {
  const services = [
    {
      title: 'Werkstatt',
      image: data.werkstattImage.childImageSharp.fluid,
      descr: 'Hier erfahren Sie mehr über unsere leistungsfähigen Meisterwerkstatt',
    },
    {
      title: 'Altgoldankauf',
      image: data.altgoldImage.childImageSharp.fluid,
      descr: 'Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an.',
    }
  ];
  return (
    <>
      <SEO title={'Startseite'}/>
      <Mounted>
        <Breakpoint small down>
          <RandomSquareGalleryImages/>
        </Breakpoint>
        <Breakpoint medium up>
          <RandomBrandHeaderImages/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Juwelier Dinger</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]} paddingRight={0}>
              <FramedImage>
                <Img fluid={data.laden.images[0].file.localFile.childImageSharp.fluid}/>
              </FramedImage>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]}>
              <p>Willkommen bei Juwelier Dinger, Ihrem vertrauenswürdigen Juweliergeschäft im Herzen von Bad Harzburg.
                Mit unserer langjährigen Erfahrung und dem Fachwissen als Goldschmiedemeisters bieten wir Ihnen eine
                exquisite Auswahl an Uhren, Schmuck, Trauringen, Eheringen und Antragsringen. In unserer
                Meisterwerkstatt kümmern wir uns mit Hingabe und Präzision um jedes Detail.</p>
              <p>Unser Sortiment umfasst eine vielfältige Auswahl an hochwertigen Uhren und Schmuckstücken, die für jede
                Gelegenheit und jeden Stil passend sind. Besonders stolz sind wir auf unsere umfangreiche Kollektion an
                Trauringen und Eheringen, die Ihre besonderen Momente unvergesslich machen. Darüber hinaus bieten wir
                individuelle Antragsringe an, die Ihre einzigartigen Liebesgeschichten perfekt ergänzen.</p>
              <p>Neben unserem beeindruckenden Sortiment legen wir großen Wert auf erstklassigen Kundenservice. Unsere
                erfahrenen Mitarbeiter stehen Ihnen mit fachkundiger Beratung zur Seite und helfen Ihnen, das perfekte
                Schmuckstück oder die ideale Uhr zu finden. Wir bieten auch einen umfassenden Reparaturservice an, um
                die Langlebigkeit und Schönheit Ihrer Kostbarkeiten zu gewährleisten.</p>
              <p>Bei Juwelier Dinger verstehen wir auch die emotionale Bedeutung von Erbstücken und bieten daher einen
                Altgoldankauf an, mit dem Sie neuen Schmuck kreieren oder vorhandene Stücke aufwerten können.</p>
              <p>Besuchen Sie uns in Bad Harzburg und erleben Sie die Welt der feinen Uhren und des exquisiten Schmucks
                bei Juwelier Dinger. Wir freuen uns darauf, Sie in unserem Geschäft zu begrüßen und Ihnen bei der
                Auswahl Ihres nächsten Schmuckstücks oder Ihrer Traumuhr behilflich zu sein.</p>
              <p>Bis bald, Ihr Thomas Dinger und Team</p>
            </Box>
          </Flex>
        </section>
        <Spacer />
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Aktuelle Kollektionen</Heading>
          <Spacer height={40}/>
          <BrandsDisplay/>
        </section>
        <Spacer />
        <section>
          <Heading tag={'h2'} icon={<BsTools/>}>Individueller Service</Heading>
          <SDisplay>
            {services.map(item => (
              <SDisplayItem width={'45%'} key={item.title}>
                <div>
                  <Img fluid={item.image} alt={item.title}/>
                  <h4>{item.title}</h4>
                  <Center>
                    <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/service">Mehr
                      erfahren</Button>
                  </Center>
                </div>
              </SDisplayItem>
            ))}
          </SDisplay>
        </section>
        <Spacer />
      </Wrapper>
      <CallToAction/>
    </>
  )
};

export default IndexPage;

export const query = graphql`
    query {
        laden: directusMediaCollection(name: {eq: "laden"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        werkstattImage: file(relativePath: {eq: "index/werkstatt.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 700, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        altgoldImage: file(relativePath: {eq: "index/altgold.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 247, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;